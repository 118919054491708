<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <!-- <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3"
            ></v-img> -->

            <h2 class="text-2xl font-weight-semibold text-uppercase">alpha calibration</h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="mb-2">Please sign-in to your account and start the adventure</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="loginForm" @submit.prevent="handleFormSubmit">
            <ac-input
              v-model="email"
              outlined
              label="Email"
              placeholder="email"
              :validation-rules="[validators.required, validators.emailValidator]"
              hide-details="auto"
              class="mb-6"
            ></ac-input>

            <ac-input
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="Password"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              :validation-rules="[validators.required]"
              hide-details="auto"
              class="mb-2"
              @appendClick="isPasswordVisible = !isPasswordVisible"
            ></ac-input>
            <div class="text-right pt-4">
              <!-- forgot link -->
              <router-link :to="{ name: 'auth-forgot-password-v1' }" class="mt-1">
                Forgot Password?
              </router-link>
            </div>

            <ac-button
              block
              labe
              color="primary"
              type="submit"
              class="mt-6"
              :loading="loading"
              :disabled="loading"
            >
              Login
            </ac-button>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-1">
          <span class="me-2"> New on our platform? </span>
          <router-link :to="{ name: 'auth-register-v1' }"> Create an account </router-link>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img class="auth-mask-bg" :src="require(`@/assets/images/svg/wave.svg`)" />
    <v-snackbar v-model="showErrorMessage" color="error" text bottom>
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <ac-icon-btn
          btn-class="me-2"
          :icon="icons.mdiClose"
          color="error"
          v-bind="attrs"
          no-tooltip
          @click="errorMessage = null"
        >
          Close
        </ac-icon-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import AcButton from '@/components/AcButton.vue'
import AcInput from '@/components/AcInput.vue'
import AcIconBtn from '@/components/AcIconBtn.vue'

import { mdiEyeOutline, mdiEyeOffOutline, mdiClose } from '@mdi/js'
import { required, emailValidator } from '@core/utils/validation'
import { mapActions } from 'vuex'

export default {
  components: {
    AcButton,
    AcInput,
    AcIconBtn,
  },
  data: () => ({
    email: null,
    password: null,
    isPasswordVisible: false,
    errorMessage: null,
    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
      mdiClose,
    },
    validators: {
      required,
      emailValidator,
    },
    loading: false,
  }),
  computed: {
    showErrorMessage: {
      get() {
        return !!this.errorMessage
      },
      set(val) {
        if (!val) this.errorMessage = null
      },
    },
  },
  methods: {
    ...mapActions('app', ['login', 'authUser']),
    async handleFormSubmit() {
      this.showError(null)
      const isFormValid = this.$refs.loginForm.validate()
      if (!isFormValid) return
      this.loading = true
      const payload = {
        email: this.email,
        password: this.password,
      }
      const { success, message } = await this.login(payload)
      if (success) {
        this.$router.push('/')
      } else {
        this.showError(message)
      }
      this.loading = false
    },
    showError(message) {
      this.errorMessage = message
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
